// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dCqPW{z-index:1;position:relative;background:#fff;padding:2em 0}.G4gbn{margin:2em 0}.NjgKD{height:46px;position:relative;padding-left:60px}.fKXfD{border-radius:5px;left:0;height:44px;position:absolute;top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "dCqPW",
	"googleButtonContainer": "G4gbn",
	"googleButton": "NjgKD",
	"googleLogo": "fKXfD"
};
module.exports = ___CSS_LOADER_EXPORT___;
